import React from 'react';
import styles from './LoginModal.module.scss'
import instructor from 'Assets/illustrations/instructor.svg'
import whiteApple from 'Assets/illustrations/apple.svg'
import whiteCap from 'Assets/illustrations/cap.svg'
import yellowCap from 'Assets/illustrations/cap-yellow.svg'
import closeIcon from 'Assets/Icons/closeIcon2.svg'
import faithLogo from 'Assets/logos/faith_logo_text2.svg'
import faithLogoWhite from 'Assets/logos/faith_logo_text_white.svg'


import { Link } from 'react-router-dom';

const LoginModal = ({ show, setModal, loginModalState }) => {

    window.addEventListener('keydown', (e) => { if (e.keyCode === 27) { setModal(false) } }); //line to press esc and go off modal

    const action = loginModalState;

    const CoursesUrl = (login = true, isStudent = false) => {
      // decide the env depending on the existing url
      const host = window.location.host
      let fullUrl = null
      if (host === "faith-journals.com") {
        // we are on production
        fullUrl = "https://courses.faith-journals.com"
      } else if (host === "sand.faith-journals.com") {
        // we are on staging
        fullUrl = "https://sand.faithcademy.com"
      } else {
        // we are on development
        fullUrl =  "http://localhost:3012"
      }
      // add the auth path to the host
      fullUrl = fullUrl + 
               (login ? "/auth/login" : "/auth/signup") + 
               (isStudent ? "/student" : "/teacher")
      // return the requested url
      return fullUrl;
    }

    return (
        <div className={`modal ${loginModalState && 'show'}`} onClick={(e) => { e.stopPropagation() }}>
          <div className={`${styles.container} ${styles.loginModal}`} onClick={(e) => e.stopPropagation()}>
            <h4>Let's get started</h4>
            <div className={styles.products}>
              
              <div className={`${styles.product} ${styles.courses}`}>
                <div className={styles.logoLine}>
                  <img src={faithLogoWhite} alt="faith journals logo" />
                  <div className={styles.coursesTitle}>courses</div>
                </div>
                <div className={styles.coursesAgeGroup}>Young adults - Adults</div>
                <div className={styles.productDescription}>
                  Courses provides a complete and simple solution to faith formation, allowing private content delivery and interaction between instructors and group participants.
                </div>
                <div className={styles.actionButtons}>
                  <div className={styles.actionButton}>
                    <Link to={{pathname: CoursesUrl(action == 'login')}} target="_top">
                      <div className={styles.imgWrapper}>
                        <img src={instructor} alt='instructor decoration'></img>
                      </div>
                      <p>I'm an Instructor</p>
                    </Link>  
                  </div>
                  <div className={styles.actionButton}>
                    <Link to={{pathname: CoursesUrl(action == 'login', true)}} target="_top">
                      <div className={styles.imgWrapper}>
                        <img src={yellowCap} alt='student decoration'></img>
                      </div>
                      <p>I'm a Student</p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className={`${styles.product} ${styles.classrooms}`}>
                <div className={styles.logoLine}>
                  <img src={faithLogo} alt="faith journals logo" />
                  <div className={styles.classroomsTitle}>classrooms</div>
                </div>
                <div className={styles.classroomsAgeGroup}>Middle - High school</div>
                <div className={styles.productDescription}>
                  Classrooms is a collaborative writing software that uses a creative pedagogy that has been proven to inspire students to write about Sacred Scripture and grow closer to God while simultaneously enhancing their writing proficiency.
                </div>
                <div className={styles.actionButtons}>
                  <div className={styles.actionButton}>
                    <Link to={action == 'login' ? '/auth/teacher' : '/auth/teacher/signup'}>
                      <div className={styles.imgWrapper}>
                        <img src={whiteApple} alt='teacher decoration'></img>
                      </div>
                      <p>I'm a Teacher</p>
                    </Link>  
                  </div>
                  <div className={styles.actionButton}>
                    <Link to={action == 'login' ? '/auth/student' : '/auth/student/signup'}>
                      <div className={styles.imgWrapper}>
                          <img src={whiteCap} alt='student decoration'></img>
                      </div>
                      <p>I'm a Student</p>
                    </Link>
                  </div>
                </div>

              </div>
            
            </div>
            
            <div className={'closingBtn'} onClick={(e) => { e.stopPropagation(); setModal(false) }}>
              <img src={closeIcon} alt='close icon' />
            </div>

          </div>
        </div>
    );
}

export default LoginModal;