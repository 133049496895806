import React from 'react';
import styles from './Pricing.module.scss';
import PricingCard from './PricingCard';

import logo from '../../Assets/logos/faith_logo_text.svg'
import SwitchButton from './Switch';
import { useState } from 'react';





const Pricing = ({ showHeader, setShowLogin }) => {
  const [selectedValue, setSelectedValue] = useState('monthly');

  const handleChange = () => {
    const newSelectedValue = selectedValue === 'monthly' ? 'annual' : 'monthly';
    setSelectedValue(newSelectedValue);
  };

  const cardData = [
    {
      type:"Small Class",
      allowance: "Up to 5 students Free",
      monthly:"0",
      yearly: null,
      benefits:["Access to all functionality", "Unlimited activities"],
      ctaText:"Start for free",
      subtitle: '',
      ctaAction:"signup_teacher"
    },
    {
      type:"Standard",
      allowance: "More than 5 students",
      monthly: selectedValue === 'annual' ? '10' : '1',
      yearly: "100",
      benefits:[
        "Access to all functionality","Unlimited activities", "Personalized onboarding support", 
        "Professional development, training and ongoing support"
      ],
      subtitle: "",
      ctaText:"Get started",
      ctaAction:"contact"
    },
    {
      type:"Spirit of Truth Workbooks",
      allowance: "",
      monthly:"6.99",
      yearly: "",
      benefits:[
       'Journals that include Sophia "Spirit of Truth" workbooks'
      ],
      subtitle: '*A Standard subscription is required to use Sophia Workbooks.',
      ctaText:"Get started",
      ctaAction:"spirit"
    }
  ]

  return (
    <div className={styles.container}>

      { showHeader && <>
        
        <h1> <img className={styles.logo} src={logo} alt="logo" /> 
            pricing
        </h1>
      </>  }

      <SwitchButton selectedValue={selectedValue} handleChange={handleChange} />
      
      <div className={`${styles.cards}`}>
        { cardData.map((i,idx) => 
          <PricingCard 
            cardData={i} 
            key={idx} 
            blue={idx===2} 
            pink={idx===1}
            light={idx===0}
            setShowLogin={setShowLogin}
            selectedValue={selectedValue}
            />
        )}
      </div>

    </div>
  )

}

export default Pricing;