import React from 'react';
import { useState } from 'react';
import ken from 'Assets/TeamPhotos/ken.png'
import richard from 'Assets/TeamPhotos/richard.jpg'

import styles from './ContactPopper.module.scss';

const ContactPopper = () => {

  const [ open, toggleOpen ] = useState(false)

  return (
    <div onMouseLeave={()=>toggleOpen(false)} className={`${styles.container} ${open && styles.open}`}>
      <div onClick={()=>toggleOpen(true)} className={styles.tabDiv}>
        <div className={styles.icon} />
        <h6>Speak with us</h6>
      </div>
      <div className={`${styles.content} ${open && styles.show}`}>
        <h5 className={styles.title}>Speak with us</h5>
        <p className={styles.bodyText}>We will be thrilled to listen to your institution's needs and share how this program can benefit your students</p>
        <div className={styles.bottom}>
            <div className={styles.left}>
              <img src={richard} alt="Photo Ken" />
              {/* <div className={`${styles.placeholder}`}></div> */}
              <div className={styles.text}>
                <p className={styles.name}>Richard Allington</p>
                <p>Education Consultant</p>
              </div>
            </div>
            <a href={"mailto:richard@faith-journals.com"} className={styles.actionBtn}> Speak with us </a>
        </div>
      </div>
    </div>
  )

}

export default ContactPopper;