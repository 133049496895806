import React, { useState, useEffect } from 'react';
import styles from './Auth.module.scss'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string'

import StudentBoomerCard from './Shared/StudentBoomerCard/StudentBoomerCard';
import logo from 'Assets/logos/logo_brown_text.svg'
import AuthHeader from './Shared/AuthHeader/AuthHeader';
import AuthForm from './AuthForm/AuthForm';
import SetPassword from './SetPassword/SetPassword';
import ForgotPassword from './ForgotPassword/ForgotPassword';


const Auth = ({ forgot, setPassword }) => {
  let { account, authType } = useParams();
  const [isSignup, setSignUp] = useState(false);
  const history = useHistory()
  const location = useLocation()
  const prePopulate = queryString.parse(location.search);
  // const account = 'teacher'


  useEffect(() => {
    account !== 'teacher' && account !== 'student' && account !== 'parent' && !forgot && !setPassword && history.push("/auth/teacher")
  })

  useEffect(() => {
    authType ? setSignUp(true) : setSignUp(false)
  }, [authType])


  const renderRightStyles = () => {
    if (account === 'teacher') return styles.teacher
    if (account === 'student') return styles.student
    if (account === 'parent') return styles.parent
    if (setPassword) return styles.student
    return styles.teacher
  }

  const selectFormToRender = () => {
    if(setPassword) return <SetPassword/>
    if(forgot) return <ForgotPassword/>
    return <AuthForm account={account} isSignup={isSignup} prePopulate={prePopulate} />
  }


  return (
    <section className={styles.loginSection}>

      <div className={styles.leftSide}>
        <AuthHeader account={account} isSignup={isSignup} />
        {selectFormToRender()}
        {(account !== 'student' && !forgot) && <StudentBoomerCard />}
      </div>

      <div className={`${styles.rightSide} ${renderRightStyles()}`}>
        <div className={styles.whiteBlock}>
          <img src={logo} alt="logo"/>
          <h1>Educating minds, preparing hearts.</h1>
        </div>
      </div>
    </section>
  );
}

export default Auth;