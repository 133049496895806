import React, { useState } from 'react';

import ReactPlayer from 'react-player/youtube'

import styles from './Hero.module.scss';
import church from '../../../../Assets/illustrations/church_coloured2.png';
import school from '../../../../Assets/illustrations/school_coloured2b.png';
import useSticky from '../../../../Helpers/useSticky';
import { Link } from 'react-router-dom';
import catholicAuthor from 'Assets/logos/sponsors/catholic_author.svg';
import smartCatholics from 'Assets/logos/sponsors/logo_smartCatholics.svg';
import parousia from 'Assets/logos/sponsors/logo_parousia.svg';
import heroImg from 'Assets/homepage/tablet_photo_video.png';
import playImg from 'Assets/homepage/play_faith_pink.svg';

import VideoPlayerModal from 'Components/UtilityComps/VideoPlayerModal/VideoPlayerModal';

import { openInNewTab } from 'Helpers/uiFunctions';

const Hero = () => {

  const { isSticky } = useSticky();
  const [ videoModalState, toggleVideoModalState ] = useState(false);

  return (
    <div className={`${styles.heroContainer} ${isSticky && styles.sticky}`}>

      <img className={`${styles.illustration} ${styles.school}`} src={school} alt="school illustration" />
      <img className={`${styles.illustration} ${styles.church}`} src={church} alt="church illustration" />

      <div className={styles.inner}>
        <h1>Growing our  relationship with God</h1>
        <h6>A <i>simple</i> and <i>personalized</i> solution for religious education and faith formation</h6>
        <div className={styles.buttons}>
          <a href={"mailto:info@faith-journals.com"} className={styles.getStartedBtn}>Speak with us</a>
      </div>

      <img src={heroImg} alt="Faith Journals image" />
      <div className={styles.inlineVideoPlayer}>
        <ReactPlayer 
          controls={true} 
          height={"204px"} 
          width={"358px"} 
          url={"https://youtu.be/7IF28B1Yfdo"} />
      </div>
      
      {/* <img src={playImg} onClick={()=>toggleVideoModalState(!videoModalState)} className={styles.play} alt="play video" /> */}
      {/* <VideoPlayerModal 
        videoModalState={videoModalState}
        setModal={toggleVideoModalState}
        url={"https://youtu.be/7IF28B1Yfdo"} /> */}

      {/* <div className={`${styles.partners}`}>
        <h4>Our partners</h4>
        <div className={`${styles.logos}`}>
          <img onClick={()=>openInNewTab('https://www.parousiamedia.com')} src={parousia} alt="Parousia logo" />
          <img onClick={()=>openInNewTab("https://smartcatholics.com")} src={smartCatholics} alt="Smart Catholics logo" />
          <img onClick={()=>openInNewTab("https://catholicauthor.us")} src={catholicAuthor} alt="Catholic Author logo" />
        </div>
      </div> */}
    </div>
    </div>
  )

}

export default Hero;