import React, { useEffect, useState, useRef } from 'react';

import styles from './Media.module.scss';
import MediaCard from './MediaCard';
import pic1 from 'Assets/homepage/media1.png';
import pic2 from 'Assets/homepage/media2.png';
import pic3 from 'Assets/homepage/media3.png';
import pic4 from 'Assets/homepage/media4.png';
import pic5 from 'Assets/homepage/media5.png';

const cardItems = [
  {
    imgSrc:pic1,
    type:"YouTube Video",
    play: true,
    duration:"32 sec long",
    title:"Faith Journals classroom publishing celebration day",
    bodyText:"Celebration on the day they received their paperback copies of the Journals.",
    link:"https://www.youtube.com/watch?v=wudIXoWtTOs"
  },
  {
    imgSrc:pic2,
    type:"Local News",
    play: true,
    duration:null,
    title:"Faith Journals in the news- Jacksonville School",
    bodyText:"“It’s just been a wonderful blessing, and in all of my 26 years of teaching...",
    link:"https://www.news4jax.com/news/local/2021/05/23/4th-graders-at-jacksonville-school-who-journaled-about-life-during-pandemic-become-published-authors/"
  },
  {
    imgSrc:pic3,
    type:"YouTube Video",
    play: true,
    duration:"7:35 min long",
    title:"Faith-Journals’ CEO interview in CatholicTV",
    bodyText:"Interview with Bishop Reed",
    link:"https://www.youtube.com/watch?v=kRFrrINE0fg"
  },
  {
    imgSrc:pic4,
    type:"Case Study",
    play: false,
    duration:"5 mins read",
    title:"Faith Journals school & sponsor pilot",
    bodyText:"Faith based elementary classroom transformed by students’ Faith Journals. Supported by corporate sponsors",
    link:"https://es-courses-static.s3.amazonaws.com/case-study-2022.pdf"
  },
  {
    imgSrc:pic5,
    type:"Infografic",
    play: false,
    duration:"1 min read",
    title:"8 Benefits of using Faith Journals",
    bodyText:"Easy, engaging, flexible ... and more",
    link:"https://es-courses-static.s3.amazonaws.com/8-benefits-of-using-faith-journals.pdf"
  },
]

const Media = () => {
  const [displayCards, setDisplayCards] = useState(cardItems)
  const firstIndex = useRef(0)
  const cardsRef = useRef()

  useEffect(() => {
    setTimeout(()=>{
      if(cardsRef.current){
        cardsRef.current.classList.add(styles.show)
      }
    },100)
  }, [displayCards])

  const reorderCards = () => {
    // show a visual feedback before re-loading the list
    cardsRef.current.classList.remove(styles.show)
    // we will re-create the array of shown items
    let reItems = []
    // now reorder the elements
    for (let i = 0; i < cardItems.length; i++) {
      // re-create the array in the same order starting from the new index
      reItems.push(cardItems[(firstIndex.current + i)%cardItems.length])
    }
    setDisplayCards(reItems)
  }

  const goPrev = () => {
    if (firstIndex.current > 0) {
      // decrease the index
      firstIndex.current--
      // reorder the cards
      reorderCards()
     } 
  }

  const goNext = () => {
    if (firstIndex.current < cardItems.length) {
      // increase the index
      firstIndex.current++
      // re-order the cards
      reorderCards()
    }
  }

  return (
    <div className={`${styles.container} ${styles.sectionContainer}`}>
      <div className={`${styles.inner}`}>
        <h2>Related articles and media</h2>
        <div className={styles.navigation}>
          <div className={styles.prev} onClick={() => goPrev()} />
          <div className={styles.next} onClick={() => goNext()} />
        </div>
        <div className={styles.cards} ref={cardsRef}>
          {
            displayCards.map((item,idx) => <MediaCard key={`card_${idx}`} item={item} />)
          }
        </div>
      </div>
    </div>
  )
}

export default Media;