import loli from 'Assets/TeamPhotos/loli.png';
import ken from 'Assets/TeamPhotos/ken.png';
import sergiu from 'Assets/TeamPhotos/sergiu.png';
import richard from 'Assets/TeamPhotos/richard.jpg';

export const teamMembers =  [
  {
    name:"Loli Lopez-Montenegro",
    title: "Co-founder",
    image:loli
  },
  {
    name:"Ken Haynes",
    title: "Co-founder",
    image:ken
  },
  {
    name:"Sergiu Truta",
    title: "Co-founder",
    image:sergiu
  },
  {
    name:"Richard Allington",
    title: "Education Consultant",
    image:richard
  }
]